<template>
  <div>
    <TheFilters
      :filter="filter"
      :loading="loading"
      @exportExcel="exportExcel"
      @clearFilter="clearFilter"
      @refresh="refresh"
      @changeFilter="changeFilter"
      @setFilterMy="setFilterMy"
    />

    <b-card class="overflow-auto">
      <TheTable
        :re-fetch="reFetch"
      />
    </b-card>

    <b-card>
      <Pagination
        :users_count="archiveList.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BCard } from 'bootstrap-vue'
import saveFilter from '@/mixins/saveFilter'
import Pagination from '@/components/pagination.vue'
import TheFilters from '../components/TheFilters.vue'
import TheTable from '../components/TheTable.vue'

export default {
  components: {
    TheFilters,
    TheTable,
    BCard,
    Pagination,
  },
  mixins: [saveFilter],
  data() {
    return {
      reFetch: false,
      loading: false,
      birthday: {
        start: null,
        end: null,
      },
      payments: {
        start: null,
        end: null,
      },
      last_action: {
        start: null,
        end: null,
      },
      filter: {
        search: null,
        name: null,
        individualEnt: null,
        nameSpi: null,
        GUARANTOR__FULL_NAME: null,
        priority: null,
        CLAIMER_CRM_COMPANY_ID: null,
        delayed: null,
        vip: null,
        region: null,
        DEBT_SUM_FROM: null,
        DEBT_SUM_TO: null,
        bankrupt: null,
        DATE_OF_DEATH: null,
        statusId: null,
        DO_STAGE: null,
        incomeType: null,
        case: null,
        right: null,
        group: null,
        MANAGER: null,
        date: null,
        paymentsDate: null,
        lastPayment: null,
        dateOfDischarge: null,
        overdue: null,
        dateOfSale: null,
        BIRTH_DATE__FROM: null,
        BIRTH_DATE__TO: null,
        PAY_DATE_FROM: null,
        PAY_DATE_TO: null,
        LAST_ACTION_DATE_FROM: null,
        LAST_ACTION_DATE_TO: null,
        BASIS__BELONGING: null,
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('archive', ['archiveList']),
  },
  watch: {
    'datePicker.birthday': {
      handler(val) {
        if (val) {
          this.filter.BIRTH_DATE__FROM = val.start
          this.filter.BIRTH_DATE__TO = val.end
        } else {
          this.filter.BIRTH_DATE__FROM = null
          this.filter.BIRTH_DATE__TO = null
        }
      },
    },
    'datePicker.payments': {
      handler(val) {
        if (val) {
          this.filter.PAY_DATE_FROM = val.start
          this.filter.PAY_DATE_TO = val.end
        } else {
          this.filter.PAY_DATE_FROM = null
          this.filter.PAY_DATE_TO = null
        }
      },
    },
    'datePicker.last_action': {
      handler(val) {
        if (val) {
          this.filter.LAST_ACTION_DATE_FROM = val.start
          this.filter.LAST_ACTION_DATE_TO = val.end
        } else {
          this.filter.LAST_ACTION_DATE_FROM = null
          this.filter.LAST_ACTION_DATE_TO = null
        }
      },
    },
  },
  async mounted() {
    try {
      this.filter = { ...this.$_getFilter() }
      await this.refresh({ ...this.filter })
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('archive', ['FETCH_SK_DEBT_ARCHIVE', 'FETCH_DEBT_EXCEL_ARCHIVE']),

    setFilterMy() {
      const filter = { ...this.$_getFilter() }

      this.birthday.start = filter.BIRTH_DATE__FROM
      this.birthday.end = filter.BIRTH_DATE__TO
      this.payments.start = filter.PAY_DATE_FROM
      this.payments.end = filter.PAY_DATE_TO
      this.last_action.start = filter.LAST_ACTION_DATE_FROM
      this.last_action.end = filter.LAST_ACTION_DATE_TO
    },

    changePaginationValue({ page, page_size }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      this.refresh()
    },

    clearFilter() {
      sessionStorage.removeItem(this.$route.name)
      this.filter = {
        GUARANTOR__FULL_NAME: null,
        DO_STAGE: null,
        search: null,
        DEBT_SUM_FROM: null,
        DEBT_SUM_TO: null,
        page: 1,
        page_size: 10,
      }
      this.birthday = {
        start: null,
        end: null,
      }
      this.payments = {
        start: null,
        end: null,
      }
      this.last_action = {
        start: null,
        end: null,
      }
    },

    async exportExcel() {
      try {
        this.loading = true
        await this.FETCH_DEBT_EXCEL_ARCHIVE(this.filter)
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.xls')
            document.body.appendChild(link)
            link.click()
            this.loading = false
          })
      } catch (error) {
        await this.$_errorToast()
      }
    },

    changeFilter(type, value) {
      this.filter.page = 1

      if (type === 'payments') {
        this.filter.PAY_DATE_FROM = value?.start || ''
        this.filter.PAY_DATE_TO = value?.end || ''
      } else if (type === 'birthday') {
        this.filter.BIRTH_DATE__FROM = value?.start || ''
        this.filter.BIRTH_DATE__TO = value?.end || ''
      } else if (type === 'last_action') {
        this.filter.LAST_ACTION_DATE_FROM = value?.start || ''
        this.filter.LAST_ACTION_DATE_TO = value?.end || ''
      } else {
        this.filter[type] = value
      }

      this.$_saveFilter(this.filter)

      this.refresh()
    },

    async refresh() {
      const sessionFilter = this.$_getFilter()

      if (sessionFilter) {
        this.filter = { ...sessionFilter }

        this.setFilterMy()
      }

      try {
        this.reFetch = true
        await this.FETCH_SK_DEBT_ARCHIVE(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.reFetch = false
    },
  },
}
</script>
