<template>
  <b-overlay
    :show="reFetch"
    rounded="sm"
  >
    <BTable
      :items="archiveList.results"
      :fields="fields"
      show-empty
    >
      <template #empty>
        <empty />
      </template>
      <template #cell(pay_his)="props">
        <div v-if="props.item.BASIS">
          <div
            v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
            :key="index"
          >
            <ul
              v-if="props.item.BASIS"
              class="d-flex align-items-center justify-content-between mb-0"
            >
              <li style="font-size: 11px">
                {{ e.PAY_DATE }}
              </li>
              <small class="ml-50">
                {{ $_moneyFormatter(e.PAY_AMOUNT) }},
              </small>
            </ul>
          </div>
        </div>
      </template>
      <template #cell(MANAGER)="props">
        <v-select
          v-model="props.item.MANAGER"
          class="w-100"
          label="first_name"
          :options="userList.results"
          @input="manager(props.item)"
        >
          <template #no-options>
            К сожалению, нет подходящих вариантов
          </template>
        </v-select>
      </template>

      <template #cell(debtors)="data">
        <router-link
          v-if="data.item.BORROWER"
          :to="`/case-card/${data.item.id}`"
        >
          {{ data.item.BORROWER.FULL_NAME }}
        </router-link>
      </template>

      <template #cell(BELONGING)="{ item }">
        <div v-if="item.BASIS">
          <v-select
            v-model="item.BASIS.BELONGING"
            label="title"
            style="width: 170px"
            :options="belongingOptions"
            :reduce="item => item.value"
            @input="changeBelonging($event, item.id)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
        </div>
      </template>

      <template #cell(BASIS)="props">
        <div v-if="props.item.BASIS">
          <span v-if="props.item.BASIS.DO_STAGE === 'voluntary_payment'">Добровольная оплата</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'claim'">Претензия</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'judical_work'">Судебная работа</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'executive_document'">Исполнительный документ</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'executive_work'">Исполнительное производство</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'stopped'">Приостановлено</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'paid'">Погашен</span>
        </div>
      </template>

      <template #cell(BASISDEBT)="props">
        <span v-if="props.item.BASIS">{{ $_moneyFormatter(props.item.BASIS.DEBT) }}</span>
      </template>

      <template #cell(EXECUTIVE_DOC_STATUS)="props">
        <b-badge
          v-if="props.item.BASIS"
          variant="primary"
        >
          {{ props.item.BASIS.EXECUTIVE_DOC_STATUS }}
        </b-badge>
      </template>
    </BTable>
  </b-overlay>
</template>
<script>
import {
  BTable,
  BOverlay,
  VBTooltip, BBadge,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import {
  mapActions,
  mapState,
} from 'vuex'
import empty from '@/components/empty.vue'
import FIELDS from './fields'

export default {
  components: {
    BBadge,
    BTable,
    VSelect,
    BOverlay,
    empty,
  },
  props: {
    reFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      fields: FIELDS.fields,
      belongingOptions: [
        {
          title: 'Купленный',
          value: 'bought',
        },
        {
          title: 'Агентский',
          value: 'agency',
        },
      ],
    }
  },
  computed: {
    ...mapState('archive', ['archiveList']),
    ...mapState('users', ['userList']),
  },
  async mounted() {
    try {
      await this.FETCH_ALL_USERS({ page_size: 1000 })
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_MANAGER', 'UPDATE_SK_DEBT_ZAYM', 'FETCH_SK_DEBT']),
    ...mapActions('users', ['FETCH_ALL_USERS']),

    push(data) {
      this.$forceUpdate()
      this.$router.push(`/case-card/${data.item.id}`)
    },

    async changeBelonging(event, id) {
      try {
        await this.UPDATE_SK_DEBT_ZAYM({
          id,
          BELONGING: event,
        })
        await this.$_okToast()
      } catch (error) {
        await this.$_errorToast()
      }
    },

    manager(props) {
      if (props.MANAGER) {
        this.UPDATE_SK_DEBT_MANAGER({
          id: props.id,
          MANAGER: props.MANAGER.id,
        })
          .then(() => {
            this.FETCH_SK_DEBT()
          })
      } else {
        this.UPDATE_SK_DEBT_MANAGER({
          id: props.id,
          MANAGER: null,
        })
          .then(() => {
            this.FETCH_SK_DEBT()
          })
      }
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
